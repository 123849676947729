<template>
  <div class="layout-wrapper layout-1">
    <div class="layout-inner">
      <app-layout-navbar />

      <div class="layout-container">

        <app-layout-sidenav />

        <div class="layout-content">

          <div v-if="busy" class="fixed-top text-right">
            <b-spinner type="grow" variant="primary" label="busy"></b-spinner>
          </div>

          <div class="router-transitions container-fluid flex-grow-1 container-p-y">
            <router-view />
          </div>

          <app-layout-footer />

        </div>
      </div>
    </div>
    <div class="layout-overlay" @click="closeSidenav"></div>

    <notifications />

  </div>
</template>

<script>
import LayoutNavbar from './LayoutNavbar'
import LayoutSidenav from './LayoutSidenav'
import LayoutFooter from './LayoutFooter'
import store from '@/store'

export default {

  name: 'app-layout-base',

  data: () => ({
    message: ''
  }),

  components: {
    'app-layout-navbar': LayoutNavbar,
    'app-layout-sidenav': LayoutSidenav,
    'app-layout-footer': LayoutFooter

  },

  mounted () {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  },

  beforeDestroy () {
    this.layoutHelpers.destroy()
  },

  methods: {

    closeSidenav () {
      this.layoutHelpers.setCollapsed(true)
    }

  },

  computed: {

    busy () {
      return store.getters.getBusy
    }
  }

}
</script>

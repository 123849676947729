import Vue from 'vue'
import Vuex from 'vuex'
import { HTTP, urls } from '@/libraries/http-common'
import { userRolesAllowed } from '@/helpers'

Vue.use(Vuex);

const state = {
  busy: false,
  typeAheadUserId: null,
  advertisingPlace: null,
  token: localStorage.getItem('token') || '',
  authStatus: '',
  isFetching: false
}

const mutations = {
  SET_BUSY (state, payload) {
    state.busy = payload;
  },

  SET_TYPE_AHEAD_USER_ID (state, payload) {
    state.typeAheadUserId = payload;
  },

  SET_ADVERTISING_PLACE (state, payload) {
    state.advertisingPlace = payload;
  },

  AUTH_REQUEST: (state) => {
    state.authStatus = 'LOADING'
  },

  AUTH_SUCCESS: (state, token) => {
    state.authStatus = 'SUCCESS'
    state.token = token
  },

  AUTH_ERROR: (state) => {
    state.authStatus = 'ERROR'
  },

  AUTH_LOGOUT: (state) => {
    state.token = ''
    state.authStatus = ''
  },

  SET_TOKEN: (state, token) => {
    state.token = token
  },

  SET_IS_FETCHING: (state, value) => {
    state.isFetching = value
  }
}

const actions = {
  setBusy (context, payload) {
    context.commit('SET_BUSY', payload);
  },

  setTypeAheadUserId (context, payload) {
    context.commit('SET_TYPE_AHEAD_USER_ID', payload);
  },

  setAdvertisingPlace (context, payload) {
    context.commit('SET_ADVERTISING_PLACE', payload);
  },

  authRequest: ({commit, state, dispatch}, userData) => {
    return new Promise((resolve, reject) => { // The Promise used for router redirect in login
      commit('AUTH_REQUEST')
      HTTP.post(urls.host + urls.login, userData)
        .then(response => {
          const token = response.data.access_token
          commit('SET_TOKEN', token)
          localStorage.setItem('token', token)
          HTTP.get(urls.userRoles)
            .then(({data}) => {
              if (userRolesAllowed(data.data)) {
                commit('AUTH_SUCCESS', token)
                resolve(response)
              }
              else throw new Error('El usuario no tiene permisos para acceder')
            })
            .catch(err => {
              commit('AUTH_ERROR', err)
              commit('SET_TOKEN', '')
              localStorage.removeItem('token')
              reject(err)
            })
        })
        .catch(err => {
          commit('AUTH_ERROR', err)
          reject(err)
        })
    })
  },

  authLogout: ({commit, dispatch}) => HTTP.post(urls.logout)
    .finally(() => {
      commit('AUTH_LOGOUT')
      localStorage.removeItem('token') // clear your user's token from localstorage
    }),

  setIsFetching: ({commit}, value) => {
    commit('SET_IS_FETCHING', value)
  },

  downloadReport: ({commit, state}, {baseUrl, query}) => {
    commit('SET_IS_FETCHING', true)
    return fetch(`${urls.host}${baseUrl}${query}`, {
      method: 'get',
      headers: new Headers({
        'Authorization': 'Bearer ' + state.token
      })
    })
      .then(resp => {
        if (resp.status !== 200) {
          throw new Error('Falló la descarga del archivo.')
        } else {
          return resp.blob()
        }
      })
      .then(blob => {
        const now = new Date()
        const month = now.getMonth()+1 < 10 ? `0${now.getMonth()+1}` : now.getMonth()+1
        const day = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate()
        const date = `${now.getFullYear()}-${month}-${day}_${now.getHours()}_${now.getMinutes()}_${now.getSeconds()}`
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        // the filename you want
        a.download = `mpv-subscriptions-all-${date}.xlsx`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        return true
      })
      .catch(() => alert('Falló la descarga del reporte!'))
      .finally(() => { commit('SET_IS_FETCHING', false) })
  }
}

const getters = {
  getBusy: state => state.busy,

  getTypeAheadUserId: state => state.typeAheadUserId,

  getAdvertisingPlace: state => state.advertisingPlace,

  isAuth: state => !!state.token,

  authStatus: state => state.authStatus,
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})

export default store;

import axios from 'axios'
import store from '@/store'
import router from '@/router'

const isProduction = process.env.NODE_ENV === 'production'

const prodHost = 'https://api.maspocovendo.com'
const devHost = 'https://api.maspocovendo.com'
// const devHost = 'https://e41c-2803-9800-9800-bf0a-f8d3-90cc-cebe-33ff.ngrok-free.app'
const host = isProduction ? prodHost : devHost

const config = {
  baseURL: `${host}`,
  headers: {
    'Authorization': '',
    ...(process.env.NODE_ENV === 'development' && { 'ngrok-skip-browser-warning': 'true' })
  }
}

export const HTTP = axios.create(config)

HTTP.interceptors.request.use(function (config) {

  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, function (err) {
  return Promise.reject(err)
})

HTTP.interceptors.response.use(undefined, function (err) {
  return new Promise(function (resolve, reject) {
    if (err.response.status === 401) {
      // if you ever get an unauthorized, logout the user
      store.dispatch('authLogout')
        .finally(() => {
          console.warn('Unauthorized: logged out')
          router.push({ path: '/login' })
        })
    }
    throw err
  })
})

export const urls = {

  host: `${host}`,

  advertisings: '/api/admin/advertisings',
  categoryHighlightPrices: '/api/admin/category-highlight-prices',
  categoryPublicationLimits: '/api/admin/category-publication-limits',
  currencies: '/api/admin/currencies',
  highlightTypes: '/api/admin/highlight-types',
  importers: '/api/admin/importer-users',
  login: '/api/login',
  logout: '/api/logout',
  me: '/api/me',
  orderStatuses: '/api/order-statuses',
  passwordReset: '/api/password/email',
  paymentMethods: '/api/payment-methods',
  processOrders: '/api/admin/orders',
  ordersList: '/api/admin/orders-list',
  publicationOrder: '/api/publications-orders',
  publicationOrderPrice: '/api/publications-orders/price-calculation',
  publicationRights: '/api/admin/publication-rights',
  publications: '/api/publications',
  reportsQueryAll: 'download=all',
  rootCategories: '/api/admin/root-categories',
  statusReasons: '/api/status-reasons',
  subscriptions: '/api/admin/subscriptions',
  subscriptionFrequencies: '/api/admin/subscription-frequencies',
  subscriptionsStatus: '/api/admin/subscription-status',
  subscriptionTypes: '/api/admin/subscription-types',
  userProfile: '/api/profiles/users',
  userRoles: '/api/users/roles',
  users: '/api/admin/users',
  createUser: '/api/admin/create/user',
  usersOrders: '/api/users-orders',
  userStatuses: '/api/user-statuses',
  userTypeAhead: '/api/admin/user/type-ahead',
  config: '/api/admin/config',
  sellPublications: '/api/admin/sell-publication-orders',
  rootCategories2: '/api/admin/categories/root',
  categories: '/api/admin/categories',
  categoryAttributes: (mla_category) => `/api/admin/category/${mla_category}/attributes`,
  marketableCategory: '/api/admin/categories',
  marketableBranch: 'admin/categories/update-marketable-branch'

}

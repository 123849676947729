import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from '@/store'
import globals from '@/globals'

// Layouts
import LayoutBase from '@/layout/LayoutBase'
import LayoutBlank from '@/layout/LayoutBlank'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '',
      redirect: { name: 'usersParticular' } },
    {
      path: '/',
      component: LayoutBase,
      children: [{
        path: 'users/particular',
        name: 'usersParticular',
        component: () => import('@/pages/UsersParticular'),
        meta: { requiresAuth: true }
      }, {
        path: 'users/commerce',
        name: 'usersCommerce',
        component: () => import('@/pages/UsersCommerce'),
        meta: { requiresAuth: true }
      }, {
        path: 'publications',
        name: 'publications',
        component: () => import('@/pages/Publications'),
        meta: { requiresAuth: true }
      }, {
        path: 'subscriptions',
        name: 'subscriptions',
        component: () => import('@/pages/Subscriptions'),
        meta: { requiresAuth: true }
      }, {
        path: 'subscriptions/advertising',
        name: 'advSubscriptions',
        component: () => import('@/pages/SubscriptionsAdvertising'),
        meta: { requiresAuth: true }
      }, {
        path: 'subscriptions/credit',
        name: 'creditSubscriptions',
        component: () => import('@/pages/SubscriptionsCredit'),
        meta: { requiresAuth: true }
      }, {
        path: 'subscriptions/publication-right',
        name: 'pubRightSubscriptions',
        component: () => import('@/pages/SubscriptionsPublicationRight'),
        meta: { requiresAuth: true }
      }, /* {
        path: 'advertisings',
        name: 'advertisings',
        component: () => import('@/components/AdvertisingList'),
        meta: { requiresAuth: true }
      }, */{
        path: 'orders',
        name: 'orders',
        component: () => import('@/pages/PaymentOrders'),
        meta: { requiresAuth: true }
      }, {
        path: 'orders/highlight',
        name: 'highlightOrders',
        component: () => import('@/pages/HighlightOrders'),
        meta: { requiresAuth: true }
      }, {
        path: 'orders/additional',
        name: 'additionalOrders',
        component: () => import('@/pages/AdditionalOrders'),
        meta: { requiresAuth: true }
      }, {
        path: 'orders/credit',
        name: 'creditOrders',
        component: () => import('@/pages/CreditOrders'),
        meta: { requiresAuth: true }
      }, {
        path: 'orders/sale',
        name: 'salesOrders',
        component: () => import('@/pages/PublicationsSalesOrders'),
        meta: { requiresAuth: true }
      }, {
        path: 'importers',
        name: 'importers',
        component: () => import('@/pages/Importers'),
        meta: { requiresAuth: true }
      }, {
        path: 'category-highlight-prices',
        name: 'categoryHightlightPrice',
        component: () => import('@/pages/CategoryHightlightPrices'),
        meta: { requiresAuth: true }
      }, {
        path: 'publications-limits',
        name: 'publicationsLimits',
        component: () => import('@/pages/PublicationsLimitsList'),
        meta: { requiresAuth: true }
      }, {
        path: 'categories-attributes-browser',
        name: 'categoriesAttributesBrowser',
        component: () => import('@/pages/CategoriesAttributesBrowser.vue'),
        meta: { requiresAuth: true }
      }, {
        path: 'currency',
        name: 'currencies',
        component: () => import('@/pages/Currencies'),
        meta: { requiresAuth: true }
      }, {
        path: 'stats',
        name: 'stats',
        component: () => import('@/pages/Stats'),
        meta: { requiresAuth: true }
      }, {
        path: 'configuration',
        name: 'configuration',
        component: () => import('@/pages/Configuration'),
        meta: { requiresAuth: true }
      }]
    }, {
      path: '/',
      component: LayoutBlank,
      children: [{
        path: 'login',
        name: 'login',
        component: () => import('@/components/AdminLogin')
      }]
    }]
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)

  if (to.matched.some(record => record.meta && record.meta.requiresAuth)) {
    if (!store.getters.isAuth) { router.push({ path: '/login' }) }
  }
  next()
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

export default router

<template>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">

    <!-- Sidenav toggle -->
    <b-navbar-nav class="align-items-lg-center mr-auto mr-lg-4" v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <!-- Navbar toggle -->
    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <!-- Brand -->
    <b-navbar-brand to="/" class="logo-admin img-fluid"></b-navbar-brand>

    <b-collapse is-nav id="app-layout-navbar">
      <b-navbar-nav class="align-items-lg-center">
        <b-nav-item @click="logout">Cerrar Sesión</b-nav-item>
      </b-navbar-nav>
    </b-collapse>

  </b-navbar>
</template>

<script>

import store from '@/store'

export default {

  name: 'app-layout-navbar',

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    },

    logout () {
      store.dispatch('authLogout')
        .then(() => {
          this.$router.push({ path: '/login' })
        })
    }
  }
}
</script>

<style>

  .logo-admin {
    background-image: url(../../images/logo.png);
    width: 300px;
    height: 30px;
    background-size: contain;
    padding: 0px;
    background-repeat: no-repeat;
  }

  .nav-link{
    margin-top: 7px;
  }
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import router from './router'
import Notifications from 'vue-notification'
import store from './store'
import moment from 'moment'

import BootstrapVue from 'bootstrap-vue'

import globals from './globals'
import Popper from 'popper.js'

// allow global access to Vue. Needed for typeAhead component
window.Vue = Vue

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Vuex)
Vue.use(Notifications)

// Global RTL flag
Vue.mixin({
  data: globals
})

Vue.filter('dateOnly', function(date){
  if (!date) return
  return moment(date,["Y-M-D h:m:s"]).format('D/M/Y')
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import { render, staticRenderFns } from "./App.vue?vue&type=template&id=b63984a8&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "@/vendor/styles/bootstrap.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "@/vendor/styles/appwork.scss?vue&type=style&index=1&prod&lang=scss&"
import style2 from "@/vendor/styles/theme-corporate.scss?vue&type=style&index=2&prod&lang=scss&"
import style3 from "@/vendor/styles/colors.scss?vue&type=style&index=3&prod&lang=scss&"
import style4 from "@/vendor/styles/uikit.scss?vue&type=style&index=4&prod&lang=scss&"
import style5 from "./style.scss?vue&type=style&index=5&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
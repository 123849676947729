<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">

<!--      <sidenav-router-link icon="ion ion-ios-easel" to="/" :exact="true">Panel general</sidenav-router-link>-->
      <sidenav-menu icon="ion ion-md-person" :open="isMenuOpen('/users')">
        <template slot="link-text">Usuarios</template>
        <sidenav-router-link :to="{ name: 'usersParticular' }" :exact="true">Particulares</sidenav-router-link>
        <sidenav-router-link :to="{ name: 'usersCommerce' }" :exact="true">Comercios</sidenav-router-link>
      </sidenav-menu>

      <sidenav-router-link icon="ion ion-md-paper" :to="{ name: 'publications' }" :exact="true">Publicaciones</sidenav-router-link>

      <sidenav-router-link icon="ion ion-md-infinite" :to="{ name: 'subscriptions' }" :exact="true">Suscripciones</sidenav-router-link>

      <sidenav-menu icon="ion ion-md-list-box" :open="isMenuOpen('/subscriptions')">
        <template slot="link-text">Tipos de suscripciones</template>
        <sidenav-router-link :to="{ name: 'advSubscriptions' }" :exact="true">Publicidades</sidenav-router-link>
        <sidenav-router-link :to="{ name: 'creditSubscriptions' }" :exact="true">Crédito</sidenav-router-link>
        <sidenav-router-link :to="{ name: 'pubRightSubscriptions' }" :exact="true">Derechos de publicación</sidenav-router-link>
      </sidenav-menu>
<!--      <sidenav-router-link icon="ion ion-md-radio" :to="{ name: 'advertisings' }" :exact="true">Publicidades</sidenav-router-link>-->
      <sidenav-router-link icon="ion ion-logo-usd" :to="{ name: 'orders' }" :exact="true">Órdenes de Pago</sidenav-router-link>

      <sidenav-menu icon="ion ion-md-list-box" :open="isMenuOpen('/orders')">
        <template slot="link-text">Tipos de órdenes</template>
        <sidenav-router-link :to="{ name: 'highlightOrders' }" :exact="true">Destaque</sidenav-router-link>
        <sidenav-router-link :to="{ name: 'additionalOrders' }" :exact="true">Adicional</sidenav-router-link>
        <sidenav-router-link :to="{ name: 'creditOrders' }" :exact="true">Crédito</sidenav-router-link>
        <sidenav-router-link :to="{ name: 'salesOrders' }" :exact="true">Ventas</sidenav-router-link>
      </sidenav-menu>

      <sidenav-router-link icon="ion ion-md-download" :to="{ name: 'importers' }" :exact="true">Importadores</sidenav-router-link>

      <sidenav-router-link icon="ion ion-md-medal" :to="{ name: 'categoryHightlightPrice' }" :exact="true">Precios de Destaques</sidenav-router-link>

      <sidenav-router-link icon="ion ion-md-lock" :to="{ name: 'publicationsLimits' }" :exact="true">Límites por Categoría</sidenav-router-link>

      <sidenav-router-link icon="ion ion-md-git-network" :to="{ name: 'categoriesAttributesBrowser' }" :exact="true">Atributos de Categorías</sidenav-router-link>

      <sidenav-router-link icon="ion ion-logo-usd" :to="{ name: 'currencies' }" :exact="true">Monedas y Valores</sidenav-router-link>

      <sidenav-router-link icon="ion ion-md-analytics" :to="{ name: 'stats' }" :exact="true">Estadísticas</sidenav-router-link>

      <sidenav-router-link icon="ion ion-md-cog" :to="{ name: 'configuration' }" :exact="true">Configuraciones</sidenav-router-link>

    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
